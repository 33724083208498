<template>
  <v-container fluid>
    <base-page-title>
      <div>
        TPCB Designer
      </div>
    </base-page-title>
    <base-navigation-button
      tooltipText="Back to Project Index"
      nextRoute="projects"
      navIcon="mdi-chevron-left"
      color="warning"
      route
      :next="false"
    />
    <v-sheet>
      <v-container>
        <v-row dense class="ma-auto">
          <v-col cols="12">
            <tpcb-control-panel
              :connectors="connectors"
              :userId="userId"
              :fixtureDesignId="fixtureDesignId"
              :projectId="projectId"
              :tpcbConfigId="tpcbConfigId"
              @saveData="saveHandler"
            />
          </v-col>
        </v-row>
      </v-container>
    </v-sheet>
    <v-sheet>
      <v-container id="svgContainer">
        <v-row dense no-gutters justify="center">
          <v-col cols="12" class="d-flex justify-center">
            <svg
              xmlns:svg="http://www.w3.org/2000/svg"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              version="1.1"
              width="44.700220cm"
              height="31.000720cm"
              viewBox="3000 5000 110930 72680"
              ref="tpcbTemplate"
            >
              <schematic-template />
              <test-point-view
                ref="testpointPlot"
                :testpointPlot="testpointPlot"
                :renderScale="renderScale"
                :svgScale="svgScale"
              />
              <connector-block
                ref="cb"
                :connectorBlocks="connectorPlot"
                :renderScale="renderScale"
                :svgScale="svgScale"
                @blockClicked="blockClickHandler"
              />
            </svg>
          </v-col>
        </v-row>
      </v-container>
    </v-sheet>
    <pin-block-modal
      :testpoints="testpointPlot"
      :pinBlock="blockObj"
      :block="blockRef"
      :dialog="pinModal"
      @saveData="savePinHandler"
      @deleteBlock="deleteBlockHandler"
      @closeModal="closePinModal"
    />
  </v-container>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
export default {
  name: "TpcbCreate",
  components: {
    TestPointView: () => import("../components/TestPointView.vue"),
    ConnectorBlock: () => import("../components/ConnectorBlock.vue"),
    SchematicTemplate: () => import("../components/SchematicTemplate.vue"),
    PinBlockModal: () => import("../components/PinBlockModal.vue"),
    TpcbControlPanel: () => import("../components/TpcbControlPanel.vue"),
  },
  data() {
    return {
      renderScale: 1,
      netModal: false,
      pinModal: false,
      svgWidth: null,
      viewBoxWidth: null,
      svgScale: null,
      netName: null,
      blockObj: null,
      blockRef: null,
    };
  },
  computed: {
    ...mapGetters({
      testPoints: "tpcb/testPoints",
      tpcbConfig: "tpcb/tpcbConfig",
      connectorBlocks: "tpcb/connectorBlocks",
      connectors: "tpcb/connectors",
      user: "auth/user",
      fixtureDesign: "fixturedesign/fixtureDesign",
    }),
    userId() {
      return this.user.pk;
    },
    projectId() {
      return this.$route.params.id;
    },
    fixtureDesignId() {
      return this.fixtureDesign.pk;
    },
    tpcbConfigId() {
      return this.tpcbConfig.pk;
    },
    connectorPlot() {
      return this.connectorBlocks
        .map((block) => ({
          ...block,
          height: 12000,
          width: 20000,
        }))
        .sort((a, b) => (a.pk > b.pk ? 1 : -1));
    },
    testpointPlot() {
      return this.testPoints.map((element) => ({
        ...element,
        refNet: `${element.ref}-${element.net}`
      }));
    }
  },
  methods: {
    ...mapActions({
      fetchConnectors: "tpcb/getConnectors",
      saveConnectorBlock: "tpcb/postConnectorBlock",
      patchingConnectorBlock: "tpcb/patchConnectorBlock",
      removeConnectorBlock: "tpcb/deleteConnectorBlock",
      updatePinBlock: "tpcb/patchPinBlock",
      toggleLoading: "ui/loading",
    }),
    blockClickHandler(block) {
      this.blockRef = block;
      this.blockObj = block["pin_blocks"]
        .map((element) => ({
          pk: element.pk,
          number: element.pin.number,
          name: element.pin.name,
          net: element.net,
          edited: false,
        }))
        .sort((a, b) => (a.number > b.number ? 1 : -1));
      this.pinModal = true;
    },
    closeNetModal() {
      this.netModal = false;
    },
    closePinModal() {
      this.pinModal = false;
    },
    rowColor(index) {
      return index % 2 !== 0 ? "#F5F5F5" : "white";
    },
    async saveHandler(payload) {
      try {
        this.toggleLoading("Saving Connector Block");
        const response = await this.saveConnectorBlock(payload);
        const connectorHTML = await this.renderAddConnectorBlock({ block: response, newBlock: true });
        let formData = new FormData();
        let file = new Blob([connectorHTML], {
          type: "image/svg+xml",
        });
        let filename = `connector_block_${response.pk}.svg`;
        formData.append("annotated_svg_file", file, filename);
        await this.patchingConnectorBlock({
          pk: response.pk,
          data: formData,
        });
        this.$refs.cb && this.$refs.cb.activateDragAndDrop(true);
        this.toggleLoading();
      } catch (err) {
        this.toggleLoading();
        this.$store.commit(
          "ui/SNACK_BAR",
          err.message || "Failed to process request, try later."
        );
      }
    },
    async savePinHandler(payload) {
      try {
        this.toggleLoading("Updating Pin Blocks");
        await Promise.all(
          payload.map(async (pin) => {
            if (pin.edited) {
              await this.updatePinBlock({ pk: pin.pk, data: { net: pin.net } });
              this.updateConnectorNet({
                pk: this.blockRef.pk,
                data: { number: pin.number, net: pin.net },
              });
            }
          })
        );
        const connectorHTML = this.updateConnectorBlock(this.blockRef);
        let formData = new FormData();
        let file = new Blob([connectorHTML], {
          type: "image/svg+xml",
        });
        let filename = `connector_block_${this.blockRef.pk}.svg`;
        formData.append("annotated_svg_file", file, filename);
        const response = await this.patchingConnectorBlock({
          pk: this.blockRef.pk,
          data: formData,
        });
        this.$refs.cb.updateConnectorList(response);
        this.toggleLoading();
      } catch (err) {
        this.toggleLoading();
        this.$store.commit(
          "ui/SNACK_BAR",
          err.message || "Failed to process request, try later."
        );
      }
    },
    calculateScale() {
      let rootRect = this.$refs.tpcbTemplate.getBoundingClientRect();
      this.renderScale = +this.viewBoxWidth / rootRect.width;
    },
    renderConnectorBlock() {
      this.connectorBlocks.forEach(async (block) => {
        await this.renderAddConnectorBlock({ block, newBlock: false });
      });
    },
    async renderAddConnectorBlock({ block, newBlock }) {
      const data = await fetch(block.annotated_svg_file);
      const svgString = await data.text();
      const svgHtml = new DOMParser().parseFromString(
        svgString,
        "image/svg+xml"
      ).documentElement;
      svgHtml.removeAttribute("content");
      const containerVar = document.getElementById("svgContainer"); //create handler for svg container
      const divVar = document.createElement("div"); // create div element for connector block svg
      divVar.setAttribute("id", `cb${block.pk}`); // set dynamic id for querying during NETS save
      divVar.setAttribute("class", "d-none"); // set to display-none
      containerVar.appendChild(divVar); // add to svg container
      divVar.innerHTML += svgHtml.outerHTML; // insert connector block svg into div element
      if (newBlock) {
        const divRef = divVar.querySelector(`div.connector-block-ref`);
        if (divRef) {
          divRef.textContent = block.ref;
        }
        const divPart = divVar.querySelector(`div.connector-block-part-number`);
        if (divPart) {
          divPart.textContent = block.connector.part_number;
        }
        await new Promise((resolve) => {
          block.pin_blocks.forEach((pinBlock, index) => {
            const nodeBlock = divVar.querySelector(
              `div.pin-block-${pinBlock.pin.number}`
            );
            if (nodeBlock) {
              nodeBlock.textContent = "Not Connected";
            }
            if (block.pin_blocks.length - 1 === index) {
              resolve();
            }
          });
        });
      }
      return divVar.innerHTML;
    },
    updateConnectorNet({ pk, data }) {
      const divBlock = document.getElementById(`cb${pk}`);
      const nodeBlock = divBlock.querySelector(`div.pin-block-${data.number}`);
      if (nodeBlock) {
        nodeBlock.textContent = data.net;
      }
    },
    updateConnectorBlock(block) {
      const divBlock = document.getElementById(`cb${block.pk}`);
      const divRef = divBlock.querySelector(`div.connector-block-ref`);
      if (divRef) {
        divRef.textContent = block.ref;
      }
      const divPart = divBlock.querySelector(`div.connector-block-part-number`);
      if (divPart) {
        divPart.textContent = block.connector.part_number;
      }
      return divBlock.innerHTML;
    },
    async deleteBlockHandler(pk) {
      try {
        await this.removeConnectorBlock(pk);
        this.$refs.cb.removeConnectorBlock(pk);
        document.getElementById(`cb${pk}`).remove();
      } catch (err) {
        this.$store.commit(
          "ui/SNACK_BAR",
          err.message || "Failed to process request, try later."
        );
      }
    },
  },
  async created() {
    this.toggleLoading("Loading TPCB data");
    try {
      await Promise.all([this.fetchConnectors()]);
      this.toggleLoading();
    } catch (err) {
      this.toggleLoading();
      this.$store.commit(
        "ui/SNACK_BAR",
        err.message || "Failed to process request, try later."
      );
    }
  },
  async mounted() {
    this.svgWidth = this.$refs.tpcbTemplate.getAttribute("width").split("c")[0];
    this.viewBoxWidth = this.$refs.tpcbTemplate
      .getAttribute("viewBox")
      .split(" ")[2];
    this.svgScale = +this.viewBoxWidth / +this.svgWidth;
    this.$refs.testpointPlot && this.$refs.testpointPlot.activateDragAndDrop(true);
    this.calculateScale();
    this.renderConnectorBlock();
    window.addEventListener("resize", this.calculateScale);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.calculateScale);
  },
};
</script>
